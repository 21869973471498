import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { HttpService } from 'app/_services/http.service';
import { MenutriggerService } from 'app/_services/menutrigger.service';

import { AuthenticationService } from 'app/auth/service';
import { environment } from 'environments/environment.prod';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private dataChangeSubscription: Subscription;
  menu:any;
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService,
    private dataService: MenutriggerService, private _coreMenuService: CoreMenuService) {



    }
  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserValue;
    this.dataService.triggerDataChange(1);
    if (currentUser != null && currentUser) {
 
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
      
        // role not authorised so redirect to not-authorized page
        this._router.navigate(['/pages/miscellaneous/not-authorized']);
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/pages/authentication/login-v2'], { queryParams: { returnUrl: state.url } });
    return false;
  }

 
}
