import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterColumn'
})
export class FilterColumnPipe implements PipeTransform {
  transform(values: any[], ...args: unknown[]): any[] {
    debugger;
    return values.filter(v => v.status ==true);
  }

}