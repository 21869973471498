import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { SortDirection } from 'app/_directive/sortable.directive';
//import { SortDirection } from 'src/app/_directive/sortable.directive';

interface SearchResult {
	records: any;
	total: number;
}

interface State {
	page: number;
	pageSize: number;
	searchTerm: string;
	sortColumn: string;
	sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

function sort(records: any, column: string, direction: string): any {
	if (direction === '' || column === '') {
		return records;
	} else {
		return [...records].sort((a, b) => {
			const res = compare(a[column], b[column]);
			return direction === 'asc' ? res : -res;
		});
	}
}

function matches(record: any, term: string, pipe: PipeTransform) {
    let keys = Object.keys(record);
    let isResult=false;
    keys.forEach(element => {
        if(typeof(record[''+element+''])=="number" && !isResult){
             isResult= pipe.transform(record[''+element+'']).includes(term);
        }
        else if(typeof(record[''+element+''])=="string" && !isResult){
            isResult= record[''+element+''].toLowerCase().includes(term.toLowerCase());
        }
    });
    return isResult;
}

@Injectable({ providedIn: 'root' })
export class SortSearchService {
	private _loading$ = new BehaviorSubject<boolean>(true);
	private _search$ = new Subject<void>();
	private _records$ = new BehaviorSubject<any>([]);
	private _total$ = new BehaviorSubject<number>(0);
    public _data!:any;

	private _state: State = {
		page: 1,
		pageSize: 10,
		searchTerm: '',
		sortColumn: '',
		sortDirection: '',
	};

	constructor(private pipe: DecimalPipe) {
		this.triggerValue();
	}
	triggerValue(){
		this._search$
			.pipe(
				tap(() => this._loading$.next(true)),
				debounceTime(200),
				switchMap(() => this._search()),
				delay(200),
				tap(() => this._loading$.next(false)),
			)
			.subscribe((result) => {
				this._records$.next(result.records);
				this._total$.next(result.total);
			});

		this._search$.next();
	}

	get records$() {
		return this._records$.asObservable();
	}
	get total$() {
		return this._total$.asObservable();
	}
	get loading$() {
		return this._loading$.asObservable();
	}
	get page() {
		return this._state.page;
	}
	get pageSize() {
		return this._state.pageSize;
	}
	get searchTerm() {
		return this._state.searchTerm;
	}

	set page(page: number) {
		this._set({ page });
	}
	set pageSize(pageSize: number) {
		this._set({ pageSize });
	}
	set searchTerm(searchTerm: string) {
		this._set({ searchTerm });
	}
	set sortColumn(sortColumn: string) {
		this._set({ sortColumn });
	}
	set sortDirection(sortDirection: SortDirection) {
		this._set({ sortDirection });
	}

	private _set(patch: Partial<State>) {
		Object.assign(this._state, patch);
		this._search$.next();
	}

	private _search(): Observable<SearchResult> {
		const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

		// 1. sort
		let records = sort(this._data, sortColumn, sortDirection);
		let total=0;
		if(records!=null){
			total = records.length;
		}
        if(total>0){
        // 2. filter
        records = records.filter((record:any) => {
           return matches(record, searchTerm, this.pipe)
        }
        );
        // 3. paginate
        records = records.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        return of({ records, total });
        }
        else{
            return of({ records, total });
        }
		
	}
}