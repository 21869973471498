import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MenutriggerService {
  private dataChangeSubject = new Subject<void>();

  dataChange$ = this.dataChangeSubject.asObservable();

  triggerDataChange(menutype :number) {
    this.dataChangeSubject.next();
  }
}
