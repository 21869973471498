import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from '@fake-db/fake-db.service';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@ctrl/ngx-rightclick';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { coreConfig } from 'app/app-config';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { fakeBackendProvider } from 'app/auth/helpers'; // used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from 'app/auth/helpers';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { ContextMenuComponent } from 'app/main/extensions/context-menu/context-menu.component';
import { AnimatedCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component';
import { BasicCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component';
import { SubMenuCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProjectSectionComponent } from './main/datamaster/project-section/project-section.component';
import { DataMasterModule } from './main/datamaster/datamaster.module';
import { ProjectviewModule } from './main/projectview/projectview.module';
import { ProjectsModule } from './main/projects/projects.module';
import { ManageemployeeComponent } from './main/hr/manageemployee/manageemployee.component';
import { HrComponent } from './main/hr/hr.component';
import { CustomPipeModule } from "./_pipe/custom-pipe.module";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CustomValidationComponent } from './custom-validation/custom-validation.component';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import moment from 'moment';

const appRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'datamanagement',
    loadChildren: () => import('./main/datamaster/datamaster.module').then(m => m.DataMasterModule)
  },
  {
    path: 'apps',
    loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'ui',
    loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'components',
    loadChildren: () => import('./main/components/components.module').then(m => m.ComponentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'extensions',
    loadChildren: () => import('./main/extensions/extensions.module').then(m => m.ExtensionsModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'tables',
    loadChildren: () => import('./main/tables/tables.module').then(m => m.TablesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'charts-and-maps',
    loadChildren: () => import('./main/charts-and-maps/charts-and-maps.module').then(m => m.ChartsAndMapsModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: '/dashboard/maindashboard',
    pathMatch: 'full'
  },
  { path: 'project', loadChildren: () => import('./main/projects/projects.module').then(m => m.ProjectsModule) },
   { path: 'purchase', loadChildren: () => import('./main/purchase/purchase.module').then(m => m.PurchaseModule) },
   { path: 'subcontract', loadChildren: () => import('./main/subcontractor/subcontractor.module').then(m => m.SubcontractorModule) },
   { path: 'projectview', loadChildren: () => import('./main/projectview/projectview.module').then(m => m.ProjectviewModule) },
   { path: 'stock', loadChildren: () => import('./main/stock/stock.module').then(m => m.StockModule) },
   { path: 'hr', loadChildren: () => import('./main/hr/hr.module').then(m => m.HrModule) },
  //  {
  //   path: '**',
  //   redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  // }
];





@NgModule({
    declarations: [
        AppComponent,
        ContextMenuComponent,
        BasicCustomContextMenuComponent,
        AnimatedCustomContextMenuComponent,
        SubMenuCustomContextMenuComponent,
        ManageemployeeComponent,
        HrComponent,
        ConfirmationDialogComponent,
        CustomValidationComponent,
    ],
    providers: [
     {provide: LocationStrategy, useClass: HashLocationStrategy},
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // ! IMPORTANT: Provider used to create fake backend, comment while using real API
        //fakeBackendProvider
    ],
    
    bootstrap: [AppComponent],
    exports: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
        //     delay: 0,
        //     passThruUnknownUrl: true
        // }),
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy'
        }),
        NgbModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot(),
        ContextMenuModule,
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        CardSnippetModule,
        LayoutModule,
        ContentHeaderModule,
        NgSelectModule,
        BsDropdownModule.forRoot(),
        FormsModule,
        CustomPipeModule,
        Ng2FlatpickrModule,
        NgxDaterangepickerMd,
        
        
    ]
})
export class AppModule {}
