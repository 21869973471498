import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbDropdownConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgbdSortableHeader, SortEvent } from 'app/_directive/sortable.directive';
import { HttpService } from 'app/_services/http.service';
import { SortSearchService } from 'app/_services/sortsearch.service';
import { environment } from 'environments/environment.prod';
import { truncate } from 'fs';
import { ToastrService } from 'ngx-toastr';
import { pid } from 'process';
import { Observable } from 'rxjs/internal/Observable';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-manageemployee',
  templateUrl: './manageemployee.component.html',
  styleUrls: ['./manageemployee.component.scss'],
  providers: [SortSearchService, DecimalPipe],
})
export class ManageemployeeComponent implements OnInit {

  projectSectionList!: Observable<any[]>;

  public contentHeader: object;

  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;

  locationList: any;
  officeList: any;
  total$!: Observable<number>;
  datalist: Observable<any[]>;
  isLoading: boolean = false;
  submitted: boolean = false;
  employeeLoginId: any;
  statelist: any;
  districtlist: any;
  departmentlist: any;
  designationlist: any;
  roleList: any;
  loginlist: any;

  cols = [
    { prop: 'employeeId', name: 'ID', status: true, visible: false, type: "number" },
    { prop: 'employeeName', name: 'Employee Name', status: true, visible: true, type: "string" },
    { prop: 'employeeCode', name: 'Employee Code', status: true, visible: true, type: "string" },
    { prop: 'contactNo', name: 'Contact No.', status: true, visible: true, type: "string" },
    { prop: 'civilId', name: 'Civil Id', status: true, visible: true, type: "string" },
    { prop: 'emailId', name: 'Email Id', status: true, visible: true, type: "string" },
    { prop: 'dob', name: 'DOB', status: true, visible: true, type: "string" },
    { prop: 'gender', name: 'Gender', status: true, visible: true, type: "string" },
    { prop: 'createdBy', name: 'Created By', status: true, visible: false, type: "string" },
    { prop: 'createdDate', name: 'Created Date', status: true, visible: true, type: "date", format: environment.DateFormat },
    { prop: 'employeeId', name: 'Action', status: true, visible: true, type: "action" },
  ];

  public form = new FormGroup({
    employeeId: new FormControl(0),
    departmentId: new FormControl(0),
    employeeName: new FormControl(''),
    contactNo: new FormControl(''),
    civilId: new FormControl(''),
    designationid: new FormControl(0),
    emailId: new FormControl(''),
    dob: new FormControl(''),
    employeeCode: new FormControl(''),
    address: new FormControl(''),
    gender: new FormControl(''),
    isActive: new FormControl(true),
    createdBy: new FormControl(0)
  });
  public formlogin = new FormGroup({
    loginId: new FormControl(0),
    employeeId: new FormControl(0),
    roleId: new FormControl(0),
    userName: new FormControl(''),
    password: new FormControl(''),
    locationTypeId: new FormControl(0),
    locationId: new FormControl(0),
    isActive: new FormControl(true),
    createdBy: new FormControl(0)
  });


  constructor(private http: HttpService, private toastr: ToastrService,
    private modalService1: NgbModal, private config: NgbDropdownConfig,
    public sortsearch: SortSearchService, private fb: FormBuilder) {
    config.autoClose = false;
    this.datalist = this.sortsearch.records$;
    this.total$ = this.sortsearch.total$;

  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Data Master',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Manage Employee Detail',
            isLink: true,
            link: '/'
          }
        ]
      }
    };

    this.getAllList();
    this.GetLocationType();
    this.GetDepartment();
    this.GetDesignation();
  }

  onSubmitLogin() {
    this.isLoading = true;
    this.submitted = true;
    if (this.form.invalid) {
      this.isLoading = false;
      return;
    }
    this.formlogin.value.employeeId = this.employeeLoginId;
    this.http.post(environment.SaveLogin, this.formlogin.value).subscribe((result: any) => {
      console.log(result.data);
      if (result.isSuccess == 1) {
        this.isLoading = false;
        this.submitted = false;
        this.getAllList();
        this.onReset();
        this.toastr.success(result.message);
        this.closeModal();
      }
      else {
        this.isLoading = false;
        this.submitted = false;
        this.toastr.error(result.message);
      }
    });
  }

  onSubmit() {
    this.isLoading = true;
    this.submitted = true;
    if (this.form.invalid) {
      console.log(this.form.value);
      this.isLoading = false;
      return;
    }
    this.http.post(environment.SaveEmployeeDetail, this.form.value).subscribe((result: any) => {
      console.log(result.data);
      if (result.isSuccess == 1) {
        this.isLoading = false;
        this.submitted = false;
        this.getAllList();
        this.onReset();
        this.toastr.success(result.message);
        this.closeModal();
      }
      else {
        this.isLoading = false;
        this.submitted = false;
        this.toastr.error(result.message);
      }
    });
  }

  onEdit(template: TemplateRef<any>, pId: number) {
    this.modalOpenDefault(template, "0");
    this.datalist.subscribe(result => {
      let item = result.filter(x => x.employeeId == pId)[0];
      console.log(item);
      this.form.patchValue({

        employeeId: item.employeeId,
        departmentId: item.departmentId,
        employeeName: item.employeeName,
        contactNo: item.contactNo,
        civilId: item.civilId,
        designationid: item.designationid,
        emailId: item.emailId,
        dob: item.dob,
        employeeCode: item.employeeCode,
        address: item.address,
        gender: item.gender,
        isActive: item.isActive,
        createdBy: item.createdBy
      });
    })
  }

  onActive(pEmployeeId: number) {
    this.http.getAll(environment.ActiveEmployeeDetailById + "?pEmployeeId=" + pEmployeeId).subscribe((result: any) => {
      if (result.isSuccess == "1") {
        this.toastr.success(result.message);
        this.getAllList();
      }
      else {
        this.toastr.warning(result.message);
      }
    });
  }
  onDelete(pEmployeeId: number) {
    this.http.getAll(environment.DeleteEmployeeDetailById + "?pEmployeeId=" + pEmployeeId).subscribe((result: any) => {
      if (result.isSuccess == "1") {
        this.toastr.success(result.message);
        this.getAllList();
      }
      else {
        this.toastr.warning(result.message);
      }
    });
  }

  onReset() {
    this.form.patchValue({
      employeeId: 0,
      departmentId: 0,
      employeeName: '',
      contactNo: '',
      civilId: '',
      designationid: 0,
      emailId: '',
      dob: '',
      employeeCode: '',
      address: '',
      gender: '',
      isActive: true,
      createdBy: 0,
    });
  }

  getAllList() {
    this.http.getAll(environment.GetEmployeeDetailAll).subscribe((result: any) => {
      if (result.isSuccess == 1) {
        console.log(result.data);
        this.sortsearch._data = result.data.sort((a: any, b: any) => {
          const dateA = new Date(a.createdDate);
          const dateB = new Date(b.createdDate);

          // Sort in descending order
          return dateB.getTime() - dateA.getTime();
        });;
        this.sortsearch.triggerValue();

        //this.projectSectionList=result.data;
      }
      else {
        this.toastr.error(result.message);
      }
    })
  }

  GetRole() {
    this.http.getAll(environment.GetRole).subscribe((result: any) => {
      if (result.isSuccess == 1) {
        console.log(result.data);
        this.roleList = result.data;
      }
      else {
        this.toastr.error(result.message);
      }
    })
  }

  GetDepartment() {
    this.http.getAll(environment.GetDepartment).subscribe((result: any) => {
      if (result.isSuccess == 1) {
        console.log(result.data);
        this.departmentlist = result.data;
      }
      else {
        this.toastr.error(result.message);
      }
    })
  }
  GetDesignation() {
    this.http.getAll(environment.GetDesignation).subscribe((result: any) => {
      if (result.isSuccess == 1) {
        console.log(result.data);
        this.designationlist = result.data;
      }
      else {
        this.toastr.error(result.message);
      }
    })
  }

  onLoginUser(template: TemplateRef<any>, pId: any) {
    this.modalOpenDefault(template, "0");
    this.employeeLoginId = pId;
    //this.employeeId=pid;
    this.GetRole();
    this.onEditLogin(pId);

  }

  onEditLogin(pId: number) {
    this.http.getAll(environment.GetLoginDetailByEmployeeId + "?pEmployeeId=" + pId).subscribe((result: any) => {
      if (result.isSuccess == 1) {
        console.log(result.data);
        this.loginlist = result.data;
        this.formlogin.patchValue({

          employeeId: this.loginlist[0].employeeId,
          loginId: this.loginlist[0].loginId,
          roleId: this.loginlist[0].roleId,
          userName: this.loginlist[0].userName,
          password: this.loginlist[0].password,
          locationTypeId: this.loginlist[0].locationTypeId,
          locationId: this.loginlist[0].locationId,
          isActive: this.loginlist[0].isActive,
          createdBy: this.loginlist[0].createdBy
        });
      }
      else {
        // this.toastr.error(result.message);
      }
    })

    this.datalist.subscribe(result => {
      let item = result.filter(x => x.employeeId == pId)[0];
      console.log(item);
      this.form.patchValue({

        employeeId: item.employeeId,
        departmentId: item.departmentId,
        employeeName: item.employeeName,
        contactNo: item.contactNo,
        civilId: item.civilId,
        designationid: item.designationId,
        emailId: item.emailId,
        dob: item.dob,
        employeeCode: item.employeeCode,
        address: item.address,
        gender: item.gender,
        isActive: item.isActive,
        createdBy: item.createdBy
      });
      this.formlogin.patchValue({
        userName: item.emailId
      });
    })

    
  }

  GetLoginDetailByEmployeeId
  get f() { return this.form.controls; }
  modalOpenDefault(modalDefault, value: string) {
    this.onReset();
    this.modalService1.open(modalDefault, {
      centered: true,
      size: 'lg'
    });
  }

  GetLocationType() {
    this.http.getAll(environment.GetLocationType).subscribe((result: any) => {
      if (result.isSuccess == 1) {
        console.log(result.data);
        this.locationList = result.data;
      }
      else {
        this.toastr.error(result.message);
      }
    })
  }

  onLoationTypeChange(pLocationTypeId: number) {
    this.http.getAll(environment.GetOfficelocationByLocationType + "?pLocationTypeId=" + pLocationTypeId).subscribe((result: any) => {
      if (result.isSuccess == 1) {
        console.log(result.data);
        this.officeList = result.data;
      }
      else {
        this.toastr.error(result.message);
      }
    })
  }

  closeModal() {
    this.modalService1.dismissAll();
  }

  private getDismissReason(reason: any): string {
    alert(reason);
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.sortsearch.sortColumn = column;
    this.sortsearch.sortDirection = direction;
  }

  print() {
    const printContent = document.getElementById("tbldata");
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt?.document.write(printContent!.innerHTML);
    WindowPrt?.document.close();
    WindowPrt?.focus();
    WindowPrt?.print();
    WindowPrt?.close();
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('tbldata');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, "EmployeeDetail.xlsx");

  }
  toggle(col: any) {
    this.cols.filter(c =>
      c.name == col.name
    )[0].status = !col.status;
  }
  getActiveColumn(col: any): any {
    return col.status == true && col.visible == true
  }
  get columns() {
    return this.cols.filter(x => x.visible == true);
  }
}