<div class="content-wrapper">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <!-- Responsive Datatable -->
        <section id="ngx-datatable-Responsive">
            <core-card-snippet>
                <h4 class="card-title" style="width: 100%;">Emaployee List
                    <button class="btn btn-primary float-right" style="  float: right!important;" (click)="modalOpenDefault(modalDefault,'0')"> + Add New Employee</button>
                </h4>
                <div class="card mx-2" id="multiple-column-form">
                    <div class="d-flex justify-content-between pb-2">
                        <div class="">
                            <select class="form-select" style="width: auto" name="pageSize"
                                [(ngModel)]="sortsearch.pageSize">
                                <option [ngValue]="10" selected="true">10</option>
                                <option [ngValue]="20">20</option>
                                <option [ngValue]="50">50</option>
                                <option [ngValue]="100">100</option>
                                <option [ngValue]="500">500</option>
                            </select>
                        </div>
                        <div class="offset-4 text-end">
                            <div class="btn-group">
                                <input id="table-complete-search" type="text" class="form-control mb-0"
                                    placeholder="search" name="searchTerm" [(ngModel)]="sortsearch.searchTerm" />
                                <button type="button" class="btn-group btn btn-primary" (click)="print()">PDF</button>
                                <button type="button" class="btn-group btn btn-primary"
                                    (click)="exportexcel()">CSV</button>
                                <div class="btn-group" dropdown #dropdown="bs-dropdown">
                                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                                        Columns <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu" role="menu"
                                        aria-labelledby="button-triggers-manual">
                                        <ng-container *ngFor="let col of columns">
                                            <li role="menuitem"><span class="dropdown-item"
                                                    [ngClass]="col.status?'active':''"
                                                    (click)="toggle(col)">{{col.name}}</span></li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="tbldata" class="card-datatable table-responsive pt-0 mb-2"
                        style="height: 650px; overflow-y: scroll;">
                        <table class="table table-striped table-bordered">
                            <thead style="  position: sticky; top: 0; z-index: 1; background-color: white; ">
                                <tr>
                                    <th>#</th>
                                    <ng-container *ngFor="let col of cols | callback:getActiveColumn">
                                        <th scope="col" sortable="{{col.prop}}" (sort)="onSort($event)">{{col.name}}
                                        </th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let unititem of datalist | async let i=index">
                                    <td>{{i+1}}</td>
                                    <ng-container *ngFor="let item of cols | callback:getActiveColumn">
                                        <td
                                            *ngIf="item.name!='Action' && item.name!='Active' && item.name!='Created Date'">
                                            <ngb-highlight [result]="unititem[''+item.prop+'']"
                                                [term]="sortsearch.searchTerm"></ngb-highlight>
                                        </td>
                                        <td *ngIf="item.name=='Active'">
                                            {{unititem.isActive ==true ? 'Active' : 'Inactive' }}
                                        </td>
                                        <td *ngIf="item.name=='Created Date'">
                                            <ngb-highlight
                                                [result]="unititem.createdDate | date : 'yyyy-MM-dd hh:mm a' "
                                                [term]="sortsearch.searchTerm"></ngb-highlight>
                                        </td>
                                        <td *ngIf="item.name=='Action'">
                                            <div class="btn-group">
                                                <button type="button"
                                                    (click)="onEdit(modalDefault,unititem[''+item.prop+''])"
                                                    class="btn btn-icon  waves-effect waves-light">
                                                    <i data-feather="edit" class="text-primary cursor-pointer"></i>
                                                </button>
                                                <!-- <button type="button" (click)="onActive(unititem[''+item.prop+''])" >
                                          <ng-container *ngIf="unititem.isActive">
                                              <i class="menu-icon tf-icons ti ti-check"></i>
                                          </ng-container>
                                          <ng-container *ngIf="!unititem.isActive">
                                              <i class="menu-icon tf-icons ti ti-x"></i>
                                          </ng-container>
                                      </button> -->
                                                <ng-container *ngIf="unititem.isLoginUser ==false">
                                                    <button type="button" (click)="onDelete(unititem[''+item.prop+''])"
                                                        class="btn btn-icon  waves-effect waves-light">
                                                        <i data-feather="trash" class="text-primary cursor-pointer"></i>
                                                    </button>
                                                </ng-container>
                                                <button type="button"
                                                    (click)="onLoginUser(modalDefaultlogin,unititem[''+item.prop+''])"
                                                    class="btn btn-icon  waves-effect waves-light">
                                                    <i data-feather="user" class="text-primary cursor-pointer"></i>
                                                </button>

                                            </div>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </core-card-snippet>
        </section>
        <!--/ Responsive Datatable -->
    </div>
</div>

<ng-template #modalDefault let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel160">Manage Employee Detail</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="form" class="form" (submit)="onSubmit()">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="select-sizing">
                <div class="row">
                    <div class="col-md-4 col-12 mb-1">
                        <label class="form-label">Employee Code</label>
                        <input formControlName="employeeCode" type="text" maxlength="15" id="first-name-column"
                            class="form-control" placeholder="Employee Code" name="fname-column" />
                    </div>
                    <div class="col-md-4 col-12 mb-1">
                        <label class="form-label">Employee Name</label>
                        <input formControlName="employeeName" type="text" id="first-name-column" maxlength="30"
                            class="form-control" placeholder="Employee Name" name="fname-column" />
                    </div>
                    <div class="col-md-4 col-12 mb-1">
                        <label class="form-label">DOB</label>
                        <input type="date" #mobileNo [ngClass]="{'form-control': true, 'is-invalid': form.get('dob')?.invalid &&
                        (form.get('dob')?.dirty || form.get('dob')?.touched)}" id="dob" name="dob"
                            formControlName="dob" placeholder="Planned Date of Start">
                    </div>
                    <div class="col-md-4 col-12 mb-1">
                        <label class="form-label">Contact No.</label>
                        <input formControlName="contactNo" type="text" id="first-name-column" maxlength="10"
                            minlength="10" class="form-control" placeholder="Contact No." name="fname-column" />
                    </div>
                    <div class="col-md-4 col-12 mb-1">
                        <label class="form-label">Email Id</label>
                        <input formControlName="emailId" type="email" id="first-name-column" maxlength="50"
                            class="form-control" placeholder="Email Id" name="fname-column" />
                    </div>
                    <div class="col-md-4 col-12">
                        <label class="form-label d-block">Gender</label>
                        <div class="form-check form-check--danger form-check-inline mt-2">
                            <input type="radio" id="customRadioM" formControlName="gender" value="M" name="gender"
                                class="form-check-input" />
                            <label class="form-check-label" for="customRadioM">Male</label>
                        </div>
                        <div class="form-check form-check--danger form-check-inline mt-2">
                            <input type="radio" id="customRadioF" formControlName="gender" value="F" name="gender"
                                class="form-check-input" />
                            <label class="form-check-label" for="customRadioF">Female</label>
                        </div>
                    </div>
                    <div class="col-md-12 col-12 mb-1">
                        <label class="form-label">Address</label>
                        <input formControlName="address" type="text" id="first-name-column" maxlength="200"
                            class="form-control" placeholder="Address" name="fname-column" />
                    </div>
                    <div class="col-md-4 col-12">
                        <label class="form-label">Civil Id </label>
                        <input formControlName="civilId" type="text" id="last-name-column" maxlength="30"
                            class="form-control" placeholder="Civil Id No." name="lname-column" />
                    </div>
                    <div class="col-md-4 col-12">
                        <label class="form-label">Department</label>
                        <div class="form-group">
                            <ng-select formControlName="departmentId" id="departmentId" #SelectSizeDefault
                                [items]="departmentlist" [searchable]="true" bindLabel="departmentName"
                                bindValue="departmentId">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <label class="form-label">Designation</label>
                        <div class="form-group">
                            <ng-select formControlName="designationid" id="designationid" #SelectSizeDefault
                                [items]="designationlist" [searchable]="true" bindLabel="designationName"
                                bindValue="designationId"> </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer float-left">
            <div class="mx-0">
                <button type="submit" class="btn btn-primary me-sm-3 me-1 waves-effect waves-light mr-1"
                    [disabled]="isLoading">
                    <ng-container *ngIf="!isLoading">Submit</ng-container>
                    <ng-container *ngIf="isLoading">
                        <span clas="indicator-progress" [style.display]="'block'">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </ng-container>
                </button>
                <button (click)="onReset()" [disabled]="form.invalid"
                    class="btn btn-primary me-sm-3 me-1 waves-effect waves-light" type="button">
                    Reset
                </button>
            </div>
        </div>
    </form>

</ng-template>
<ng-template #modalDefaultlogin let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel160">Manage Login Detail</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <!-- (submit)="onSubmitLogin()" -->
    <form [formGroup]="formlogin" class="form" (submit)="onSubmitLogin()">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="card-body select-sizing">
                <div class="row">
                    <div class="col-md-4 col-12">
                        <label class="form-label">UserName</label>
                        <input formControlName="userName" type="text" id="first-name-column" maxlength="40"
                            minlength="3" class="form-control" placeholder="User Name" name="fname-column" readonly/>
                    </div>
                    <div class="col-md-4 col-12">
                        <label class="form-label">Password</label>
                        <input formControlName="password" type="text" id="first-name-column" maxlength="20"
                            minlength="6" class="form-control" placeholder="Password" name="fname-column" />
                    </div>
                    <div class="col-md-4 col-12">
                        <label class="form-label">RoleId</label>
                        <div class="form-group">
                            <ng-select formControlName="roleId" id="roleId" #SelectSizeDefault [items]="roleList"
                                [searchable]="true" bindLabel="roleName" bindValue="roleId">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <label class="form-label">Location Type</label>
                        <ng-select formControlName="locationTypeId" id="locationTypeId" #SelectSizeDefault
                            [items]="locationList" (ngModelChange)="onLoationTypeChange($event)" [searchable]="false"
                            bindLabel="locationTypeName" bindValue="locationTypeId">
                            <!--/ Select Size default -->
                        </ng-select>
                    </div>
                    <div class="col-md-4 col-12">
                        <label class="form-label">Location</label>
                        <ng-select formControlName="locationId" id="locationId" #SelectSizeDefault [items]="officeList"
                            [searchable]="true" bindLabel="locationName" bindValue="officeLocationId">
                            <!--/ Select Size default -->
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <div class="col-lg-12">
                <button type="submit" class="btn btn-primary me-sm-3 me-1 waves-effect waves-light mr-1"
                    [disabled]="isLoading">
                    <ng-container *ngIf="!isLoading">Submit</ng-container>
                    <ng-container *ngIf="isLoading">
                        <span clas="indicator-progress" [style.display]="'block'">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </ng-container>
                </button>
                <button (click)="onReset()" [disabled]="form.invalid"
                    class="btn btn-primary me-sm-3 me-1 waves-effect waves-light" type="button">
                    Reset
                </button>
            </div>
        </div>
    </form>

</ng-template>
<!-- Feather icon-s section end -->