export const environment = {
  production: true,
  hmr: false,
  config:{
    //apiUrl:  'https://localhost:44329/api/' 
  apiUrl :   'https://www.ecommandar.com/dharaAPI/api/'
 //  apiUrl:'https://localhost:44350/api/'
    //apiUrl:'https://www.indianfilms.in/eBuilderAPI/api/'
  },
  appVersion: 'v1.0.0',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
//  apiUrl:'https://localhost:44329/api/',
//  imgUrl :'https://localhost:44329/',

 imgUrl : 'https://www.ecommandar.com/dharaAPI/',
  apiUrl:'https://www.ecommandar.com/dharaAPI/api/',


//  apiUrl: 'https://www.indianfilms.in/eBuilderAPI/api/',
  DateFormat:'yyyy-MM-dd hh:mm a',
  appThemeName: 'Metronic',
  appPurchaseUrl: '',
  appHTMLIntegration: '',
  appPreviewUrl: '',
  appPreviewAngularUrl: '', 
  appPreviewDocsUrl: '',
  appPreviewChangelogUrl: '',
  // inspections Api urls

  //Login
  Login:"LoginDetail/GetLoginDetailByUserPass",
  GetLoginDetailAll:"LoginDetail/GetLoginDetailAll",
  SaveLogin:"LoginDetail/SaveLogin",
  GetLoginDetailByEmployeeId: "LoginDetail/GetLoginDetailByEmployeeId",
  GetLoginDetailuser:"LoginDetail/GetLoginDetailuser",
  ApprovalLoginDetailById:"LoginDetail/ApprovalLoginDetailById",


  GetRequirementStatusAll:"Requirement/GetRequirementStatusAll",
  GetRequirementStatusById:"Requirement/GetRequirementStatusById",
  SaveRequirementStatus:"Requirement/SaveRequirementStatus",
  DeleteRequirementStatusById:"Requirement/DeleteRequirementStatusById",
  ActiveRequirementStatusById:"Requirement/ActiveRequirementStatusById",
  GetRequirementStatus:"Requirement/GetRequirementStatus",

  GetRequirementTypeAll:"Requirement/GetRequirementTypeAll",
  GetRequirementTypeById:"Requirement/GetRequirementTypeById",
  SaveRequirementType:"Requirement/SaveRequirementType",
  DeleteRequirementTypeById:"Requirement/DeleteRequirementTypeById",
  ActiveRequirementTypeById:"Requirement/ActiveRequirementTypeById",
  GetRequirementType:"Requirement/GetRequirementType",


  SaveClientRequirement:"ClientRequirement/SaveClientRequirement",
  GetClientRequirementAll:"ClientRequirement/GetClientRequirementAll",
  GetClientRequirementById:"ClientRequirement/GetClientRequirementById",
  DeleteClientRequirementById:"ClientRequirement/DeleteClientRequirementById",


  DeleteEmddetailById:"Emddetail/DeleteEmddetailById",
  GetEmddetailAll:"Emddetail/GetEmddetailAll",
  GetEmddetailById:"Emddetail/GetEmddetailById",
  SaveEmddetail:"Emddetail/SaveEmddetail",
  GetEmddetailByProjectClientId:"Emddetail/GetEmddetailByProjectClientId",

  DeleteSecurityDepositDetailById:"SecurityDepositDetail/DeleteSecurityDepositDetailById",
  GetSecurityDepositDetailAll:"SecurityDepositDetail/GetSecurityDepositDetailAll",
  GetSecurityDepositDetailById:"SecurityDepositDetail/GetSecurityDepositDetailById",
  SaveSecurityDepositDetail:"SecurityDepositDetail/SaveSecurityDepositDetail",
  GetSecurityDepositDetailByProjectClientId:"SecurityDepositDetail/GetSecurityDepositDetailByProjectClientId",

  GetRolePermissionByRoleId:"RolePermission/GetRolePermissionByRoleId",

  DeleteProjectSectionById:"ProjectSection/DeleteProjectSectionById",
  GetProjectSectionAll:"ProjectSection/GetProjectSectionAll",
  GetProjectSectionById:"ProjectSection/GetProjectSectionById",
  SaveProjectSection:"ProjectSection/SaveProjectSection",
  GetProjectSection:"ProjectSection/GetProjectSection",
  ActiveProjectSectionById:"ProjectSection/ActiveProjectSectionById",

  DeleteProjectCategoryById:"ProjectCategory/DeleteProjectCategoryById",
  GetProjectCategoryAll:"ProjectCategory/GetProjectCategoryAll",
  GetProjectCategoryById:"ProjectCategory/GetProjectCategoryById",
  SaveProjectCategory:"ProjectCategory/SaveProjectCategory",
  GetProjectCategory:"ProjectCategory/GetProjectCategory",
  ActiveProjectCategoryById:"ProjectCategory/ActiveProjectCategoryById",

  GetProjectDetailAllDDL:"ProjectDetail/GetProjectDetailAllDDL",
  DeleteProjectDetailById:"ProjectDetail/DeleteProjectDetailById",
  
  DeleteTaxTypeById:"TaxType/DeleteTaxTypeById",
  GetTaxTypeAll:"TaxType/GetTaxTypeAll",
  GetTaxTypeById:"TaxType/GetTaxTypeById",
  SaveTaxType:"TaxType/SaveTaxType",
  GetTaxType:"TaxType/GetTaxType",
  ActiveTaxTypeById:"TaxType/ActiveTaxTypeById",
  
  DeleteLocationAreaById:"LocationArea/DeleteLocationAreaById",
  GetLocationAreaAll:"LocationArea/GetLocationAreaAll",
  GetLocationAreaById:"LocationArea/GetLocationAreaById",
  SaveLocationArea:"LocationArea/SaveLocationArea",
  GetLocationArea:"LocationArea/GetLocationArea",
  ActiveLocationAreaById:"LocationArea/ActiveLocationAreaById",

  //Bank
  SaveBank:"BankDetail/SaveBankDetail",
  GetNonDeleteBank:"BankDetail/GetBankDetailAll",
  DeleteBankDetailById:"BankDetail/DeleteBankDetailById",
  ActiveActionBankById:"BankDetail/ActiveActionById",
  GetBankDetailById:"BankDetail/GetBankDetailById",
  ExistsBank:"BankDetail/ExistsBank",

  //MaterialCategory
  SaveMaterialCategory:"MaterialCategory/SaveMaterialCategory",
  GetNonDeleteMaterialCategory:"MaterialCategory/GetMaterialCategoryAll",
  DeleteMaterialCategoryById:"MaterialCategory/DeleteMaterialCategoryById",
  ActiveActionMaterialCategoryById:"MaterialCategory/ActiveActionMaterialCategoryById",
  GetMaterialCategoryById:"MaterialCategory/GetMaterialCategoryById",
  ExistsMaterialCategory:"MaterialCategory/ExistsMaterialCategory",
  GetMaterialCategory:"MaterialCategory/GetMaterialCategory",
  GetMaterialCategoryByTypeId:"MaterialCategory/GetMaterialCategoryByTypeId",

  //UnitType
  SaveUnitType:"Unit/SaveUnitType",
  GetNonDeleteUnitType:"Unit/GetUnitTypeAll",
  DeleteUnitTypeById:"Unit/DeleteUnitTypeById",
  ActiveActionUnitTypeById:"Unit/ActiveActioUnitTypeById",
  GetUnitTypeById:"Unit/GetUnitTypeById",
  ExistsUnitType:"Unit/ExistsUnitType",
  GetUnit:"Unit/GetUnit",

  //Material Detail
  SaveMaterialDetail:"MaterialDetail/SaveMaterialDetail",
  GetNonDeleteMaterialDetail:"MaterialDetail/GetMaterialDetailAll",
  DeleteMaterialDetailById:"MaterialDetail/DeleteMaterialDetailById",
  ActiveActionMaterialDetailById:"MaterialDetail/ActiveActionMaterialDetailById",
  GetMaterialDetailById:"MaterialDetail/GetMaterialDetailById",
  ExistsMaterialDetail:"MaterialDetail/ExistsMaterialDetail",

  //Purchase Request
  GetPurchaseRequestAll:"PurchaseRequest/GetPurchaseRequestAll",
  DeletePurchaseRequestById:"PurchaseRequest/DeletePurchaseRequestById",
  GetPurchaseRequestById:"PurchaseRequest/GetPurchaseRequestById",
  SavePurchaseRequest:"PurchaseRequest/SavePurchaseRequest",
  GetPurchaseRequest:"PurchaseRequest/GetPurchaseRequest",
  GetPurchaseRequestbyLocation:"PurchaseRequest/GetPurchaseRequestbyLocation",
  LockPurchaseRequestById:"PurchaseRequest/LockPurchaseRequestById",
  GetPurchaseRequestbyLoginId:"PurchaseRequest/GetPurchaseRequestbyLoginId",
  GMApprovalPurchaseRequestById:"PurchaseRequest/GMApprovalPurchaseRequestById",
  GetApprovedPurchaseRequest:"PurchaseRequest/GetApprovedPurchaseRequest",


  //PR Quatation

  SavePRQuatation:"Prquatation/SavePRQuatation",
  GetPrquatationByPurchaseRequestId: "Prquatation/GetPrquatationByPurchaseRequestId",
  GetQuatationById:"Prquatation/GetQuatationById",

  // Purchase Order 

  SavePurchaseOrder:"PurchaseOrder/SavePurchaseOrder",
  GetPurchaseOrder:"PurchaseOrder/GetPurchaseOrder",
  GetPurchaseOrderById:"PurchaseOrder/GetPurchaseOrderById",
  DeletePurchaseOrderById:"PurchaseOrder/DeletePurchaseOrderById",

  // Purchase Bill

  SavePurchaseBill:"PurchaseBill/SavePurchaseBill",
  GetPurchaseBillAll:"PurchaseBill/GetPurchaseBillAll",
  GetPurchaseBillById:"PurchaseBill/GetPurchaseBillById",
  DeletePurchaseBillById:"PurchaseBill/DeletePurchaseBillById",
  UpdatePurchaseBillStatus: "PurchaseBill/UpdatePurchaseBillStatus",
  UpdatePurchaseBillStatusLock:"PurchaseBill/UpdatePurchaseBillStatusLock",
  GetPurchaseBillByLocation:"PurchaseBill/GetPurchaseBillByLocation",
  
  UpadateStock:"PurchaseBillItemDetail/UpadateStock",
  UpdateBillStockById:"PurchaseBill/UpdateBillStockById",
//PurchaseBillItemDetail

SavePurchaseBillItemDetail:"PurchaseBillItemDetail/SavePurchaseBillItemDetail",
GetPurchaseBillItemDetailByPurchaseBillId:"PurchaseBillItemDetail/GetPurchaseBillItemDetailByPurchaseBillId",
DeletePurchaseBillItemDetailById:"PurchaseBillItemDetail/DeletePurchaseBillItemDetailById",
AddPurchaseBillItemToStock : "PurchaseBillItemDetail/AddPurchaseBillItemToStock",
GenerateReportAsync: "PurchaseBillItemDetail/GenerateReportAsync",



//Client Details

  DeleteClientDetailById:"ClientDetail/DeleteClientDetailById",
  GetClientDetailAll:"ClientDetail/GetClientDetailAll",
  GetClientDetailById:"ClientDetail/GetClientDetailById",
  SaveClientDetail:"ClientDetail/SaveClientDetail",
  GetClientDetail:"ClientDetail/GetClientDetail",
  ActiveClientDetailById:"ClientDetail/ActiveClientDetailById",

  SaveProjectDetail:"ProjectDetail/SaveProjectDetail",
  OnGoingProjectList:"ProjectDetail/OnGoingProjectList",
  CompleteProjectList:"ProjectDetail/CompleteProjectList",
  GetProjectDetailById:"ProjectDetail/GetProjectDetailById",

  DeleteEmployeeDetailById:"EmployeeDetail/DeleteEmployeeDetailById",
  GetEmployeeDetailAll:"EmployeeDetail/GetEmployeeDetailAll",
  GetEmployeeDetailById:"EmployeeDetail/GetEmployeeDetailById",
  GetEmployeeDetail:"EmployeeDetail/GetEmployeeDetail",
  SaveEmployeeDetail:"EmployeeDetail/SaveEmployeeDetail",
  ActiveEmployeeDetailById:"EmployeeDetail/ActiveEmployeeDetailById",
  GetEmployeeDetails:"EmployeeDetail/GetEmployeeDetails",


  
  GetBasicWageType:"BasicWageType/GetBasicWageType",


  DeleteContractorDetailById:"ContractorDetail/DeleteContractorDetailById",
  GetContractorDetailAll:"ContractorDetail/GetContractorDetailAll",
  GetContractorDetail:"ContractorDetail/GetContractorDetail",
  GetContractorDetailById:"ContractorDetail/GetContractorDetailById",
  SaveContractorDetail:"ContractorDetail/SaveContractorDetail",
  ActiveContractorDetailById:"ContractorDetail/ActiveContractorDetailById",

  DeleteLabourDetailById:"LabourDetail/DeleteLabourDetailById",
  GetLabourDetailAll:"LabourDetail/GetLabourDetailAll",
  GetLabourDetail:"LabourDetail/GetLabourDetail",
  GetLabourByContractorIdWorkDate:"LabourDetail/GetLabourByContractorIdWorkDate",
  GetLabourDetailByContractorId:"LabourDetail/GetLabourDetailByContractorId",
  GetLabourDetailById:"LabourDetail/GetLabourDetailById",
  SaveLabourDetail:"LabourDetail/SaveLabourDetail",
  ActiveLabourDetailById:"LabourDetail/ActiveLabourDetailById",
  GetLabourByContractorId:"LabourDetail/GetLabourByContractorId",

  GetWorkType:"WorkType/GetWorkType",
  DeleteWorkTypeById:"WorkType/DeleteWorkTypeById",
  GetWorkTypeAll:"WorkType/GetWorkTypeAll",
  GetWorkTypeById:"WorkType/GetWorkTypeById",
  SaveWorkType:"WorkType/SaveWorkType",
  ActiveWorkTypeById:"WorkType/ActiveWorkTypeById",

  SaveLabourattend:"Labourattendance/SaveLabourattend",
  DeleteProjectSupervisorById:"ProjectSupervisor/DeleteProjectSupervisorById",
  GetProjectSupervisorAll:"ProjectSupervisor/GetProjectSupervisorAll",
  GetProjectSupervisorById:"ProjectSupervisor/GetProjectSupervisorById",
  GetProjectSupervisorByProjectId:"ProjectSupervisor/GetProjectSupervisorByProjectId",
  SaveProjectSupervisor:"ProjectSupervisor/SaveProjectSupervisor",

//Account Heads

GetAccountHeadById: "AccountHead/GetAccountHeadById",
DeleteAccountHeadById: "AccountHead/DeleteAccountHeadById",
GetAccountHeadAll: "AccountHead/GetAccountHeadAll",
GetAccountHead: "AccountHead/GetAccountHead",
SaveAccountHead: "AccountHead/SaveAccountHead",
ActiveAccountHeadById: "AccountHead/ActiveAccountHeadById",
GetAccountHeadByAccountTypeId:"AccountHead/GetAccountHeadByAccountTypeId",
//Account Type
GetAccountType: "AccountType/GetAccountType",

//Payment Mode
GetPaymentMode:"PaymentMode/GetPaymentMode",

//Payment Transaction

DeletePaymentTransactionById :"PaymentTransaction/DeletePaymentTransactionById",
GetPaymentTransactionAll:"PaymentTransaction/GetPaymentTransactionAll",
GetPaymentTransaction:"PaymentTransaction/GetPaymentTransaction",
GetPaymentTransactionById:"PaymentTransaction/GetPaymentTransactionById",
SavePaymentTransaction:"PaymentTransaction/SavePaymentTransaction",
ActivePaymentTransactionById:"PaymentTransaction/ActivePaymentTransactionById",
GetPaymentTransactionBySuprtVisorPayment:"PaymentTransaction/GetPaymentTransactionBySuprtVisorPayment",

//Holiday
DeleteHolidayById:"Holiday/DeleteHolidayById",
GetHolidayAll:"Holiday/GetHolidayAll",
GetHoliday:"Holiday/GetHoliday",
GetHolidayById:"Holiday/GetHolidayById",
SaveHoliday:"Holiday/SaveHoliday",
ActiveHolidayById:"Holiday/ActiveHolidayById",

// Account Details
DeleteAccountDetailById:"AccountDetail/DeleteAccountDetailById",
GetAccountDetailAll:"AccountDetail/GetAccountDetailAll",
GetAccountDetail:"AccountDetail/GetAccountDetail",
GetAccountDetailById:"AccountDetail/GetAccountDetailById",
SaveAccountDetail:"AccountDetail/SaveAccountDetail",
ActiveAccountDetailById:"AccountDetail/ActiveAccountDetailById",

//Supplier Category
DeleteSupplierCategoryById:"SupplierCategory/DeleteSupplierCategoryById",
GetSupplierCategoryAll:"SupplierCategory/GetSupplierCategoryAll",
GetSupplierCategory:"SupplierCategory/GetSupplierCategory",
GetSupplierCategoryById:"SupplierCategory/GetSupplierCategoryById",
SaveSupplierCategory:"SupplierCategory/SaveSupplierCategory",
ActiveSupplierCategoryById:"SupplierCategory/ActiveSupplierCategoryById",

//Supplier
DeleteSupplierDetailById:"SupplierDetail/DeleteSupplierDetailById",
GetSupplierDetailAll:"SupplierDetail/GetSupplierDetailAll",
GetSupplierDetail:"SupplierDetail/GetSupplierDetail",
GetSupplierDetailById:"SupplierDetail/GetSupplierDetailById",
SaveSupplierDetail:"SupplierDetail/SaveSupplierDetail",
ActiveSupplierDetailById:"SupplierDetail/ActiveSupplierDetailById",


GetPriorityStatus:"PriorityStatus/GetPriorityStatus",

//Role 
GetRole:"Role/GetRole",
DeleteRoleById:"Role/DeleteRoleById",
ActiveRoleById:"Role/ActiveRoleById",
GetRoleAll:"Role/GetRoleAll",
GetRoleById:"Role/GetRoleById",
SaveRole:"Role/SaveRole",

//Department
DeleteDepartmentById:"Department/DeleteDepartmentById",
ActiveDepartmentById:"Department/ActiveDepartmentById",
GetDepartment:"Department/GetDepartment",
GetDepartmentAll:"Department/GetDepartmentAll",
GetDepartmentById:"Department/GetDepartmentById",
SaveDepartment:"Department/SaveDepartment",

//Designation
GetDesignation:"Designation/GetDesignation",
DeleteDesignationById:"Designation/DeleteDesignationById",
ActiveDesignationById:"Designation/ActiveDesignationById",
GetDesignationAll:"Designation/GetDesignationAll",
GetDesignationById:"Designation/GetDesignationById",
SaveDesignation:"Designation/SaveDesignation",

//Document
DeleteDocumentDetailById:"DocumentDetail/DeleteDocumentDetailById",
GetDocumentDetailAll:"DocumentDetail/GetDocumentDetailAll",
GetDocumentDetail:"DocumentDetail/GetDocumentDetail",
GetDocumentDetailById:"DocumentDetail/GetDocumentDetailById",
SaveDocumentDetail:"DocumentDetail/SaveDocumentDetail",
ActiveDocumentDetailById:"DocumentDetail/ActiveDocumentDetailById",

//Document Revision
DeleteDocumentRevisionDetailById:"DocumentRevisionDetail/DeleteDocumentRevisionDetailById",
GetDocumentRevisionDetailAll:"DocumentRevisionDetail/GetDocumentRevisionDetailAll",
GetDocumentRevisionDetail:"DocumentRevisionDetail/GetDocumentRevisionDetail",
GetDocumentRevisionDetailById:"DocumentRevisionDetail/GetDocumentRevisionDetailById",
SaveDocumentRevisionDetail:"DocumentRevisionDetail/SaveDocumentRevisionDetail",
ActiveDocumentRevisionDetailById:"DocumentRevisionDetail/ActiveDocumentRevisionDetailById",

//Subcontract
SaveSubcontractorWork:"SubcontractorWork/SaveSubcontractorWork",

  
//Project Section s \
SavePProjectSection : "PProjectSection/SavePProjectSection",
GetPprojectSectionByProjectId: "PProjectSection/GetPprojectSectionByProjectId",
GetPprojectSectionAll : "PProjectSection/GetPprojectSectionAll",
GetprojectSectionByProjectIdOrALL:"PProjectSection/GetprojectSectionByProjectIdOrALL",
DeletePprojectSectionById:"PProjectSection/DeletePprojectSectionById",
GetprojectSectionByProjectId:"PProjectSection/GetprojectSectionByProjectId",
GetPprojectCategoryBySectionId:"PProjectSection/GetPprojectCategoryBySectionId",
SavePProjectSectionBulk:"PProjectSection/SavePProjectSectionBulk",

// Section Category  \
SaveSectionCategory : "SectionCategory/SaveSectionCategory",
GetSectionCategoryBySectionId : "SectionCategory/GetSectionCategoryBySectionId",
GetCategoryBySectionDetailId: "SectionCategory/GetCategoryBySectionDetailId",
DeleteSectionCategoryById:"SectionCategory/DeleteSectionCategoryById",
GetSectionCategory:"SectionCategory/GetSectionCategory",
//Project Section Category 

SaveProjectSectionCategory : "ProjectSectionCategory/SaveProjectSectionCategory",
GetProjectSectionCategoryFilterById:"ProjectSectionCategory/GetProjectSectionCategoryFilterById",
DeleteProjectSectionCategoryById : "ProjectSectionCategory/DeleteProjectSectionCategoryById",
GetSectionCategoryById:"ProjectSectionCategory/GetSectionCategoryById",
GetSectionCategoryBySectionDetailId:"ProjectSectionCategory/GetSectionCategoryBySectionDetailId",
GetSectionCategoryListById:"ProjectSectionCategory/GetSectionCategoryListById",



// Country 
GetCountryActive : "CSDTC/GetCountryActive",

GetStateByCountryId: "CSDTC/GetStateByCountryId",
GetDistrictByStateId:"CSDTC/GetDistrictByStateId",
GetTehsilByDistrictId:"CSDTC/GetTehsilByDistrictId",
GetCityByTehsilId:"CSDTC/GetCityByTehsilId",

// Menu

GetMenu:"MenuDetail/GetMenu",
GetMenuDetailByTypeId:"MenuDetail/GetMenuDetailByTypeId",
//Project Location

SaveProjectLocation:"ProjectLocation/SaveProjectLocation",
GetProjectLocationAllByProjectId: "ProjectLocation/GetProjectLocationAllByProjectId",
DeleteProjectLocationById:"ProjectLocation/DeleteProjectLocationById",

//Work Location 
SaveWorkLocation :"WorkLocation/SaveWorkLocation",
GetWorkLocationByALLORById : "WorkLocation/GetWorkLocationByALLORById",
DeleteWorkLocationById : "WorkLocation/DeleteWorkLocationById",
GetWorkLocationByProjectSectionId:"WorkLocation/GetWorkLocationByProjectSectionId",

//Status 
GetStatusTypeAll:"StatusType/GetStatusTypeAll",

//DWPCategory

SaveDwpcategory:"Dwpcategory/SaveDwpcategory",
GetDwpcategoryAll:"Dwpcategory/GetDwpcategoryAll",
DeleteDwpcategoryById:"Dwpcategory/DeleteDwpcategoryById",
SaveDwpcategoryBulk:"Dwpcategory/SaveDwpcategoryBulk",
GetLocationBySectionCategory : "Dwpcategory/GetLocationBySectionCategory",
GetLocationsBySectionCategory:"Dwpcategory/GetLocationsBySectionCategory",
Dwpcategory:"Dwpcategory/DistrictWisesDPR",
SchemeWiseDPR:"Dwpcategory/SchemeWiseDPR",
//transport detail

SaveConsigneesDetail:"ConsigneesDetail/SaveConsigneesDetail",
GetConsigneesDetail:"ConsigneesDetail/GetConsigneesDetail",
DeleteConsigneesDetailById:"ConsigneesDetail/DeleteConsigneesDetailById",


//Vehical Detail
GetVehicleDetail:"VehicleDetail/GetVehicleDetail",
SaveVehicleDetail:"VehicleDetail/SaveVehicleDetail",
DeleteVehicleDetailById:"VehicleDetail/DeleteVehicleDetailById",

//Vehical type 
GetVehicleType:"VehicleType/GetVehicleType",

//Work Duration Type 
GetWorkDurationType:"WorkDurationType/GetWorkDurationType",

//Cover Agreement

SaveCoverAgreement:"CoverAgreement/SaveCoverAgreement",
GetCoverAgreement:"CoverAgreement/GetCoverAgreement",
GetCoverAgreementByProjectId:"CoverAgreement/GetCoverAgreementByProjectId",
DeleteCoverAgreementById:"CoverAgreement/DeleteCoverAgreementById",

//LocationScope 

GetLocationsForScopBySectionCategory: "LocationScope/GetLocationsForScopBySectionCategory",
SaveLocationScopeBulk:"LocationScope/LocationScopeBulk",

//OfficeLocation

GetOfficelocation:"Officelocation/GetOfficelocation",
GetOfficelocationByLocationType: "Officelocation/GetOfficelocationByLocationType",
SaveOfficelocation:"Officelocation/SaveOfficelocation",
GetOfficelocationAll:"Officelocation/GetOfficelocationAll",
DeleteOfficelocationById:"Officelocation/DeleteOfficelocationById",
ActiveOfficelocationById:"Officelocation/ActiveOfficelocationById",
//LocationType

GetLocationType:"LocationType/GetLocationType",

//Transport Details
GetTransportDetail:"TransportDetail/GetTransportDetail",

//Stock Details

SaveStockDetail:"StockDetail/SaveStockDetail",
GetStockDetailAll:"StockDetail/GetStockDetailAll",
DeleteStockDetailById:"StockDetail/DeleteStockDetailById",
GetStockQuantity:"StockDetail/GetStockQuantity",
SaveBillStock:"StockDetail/SaveBillStock",
GetStockMaterialCategory: "StockDetail/GetStockMaterialCategory",
GetStockMaterial: "StockDetail/GetStockMaterial",
GetStockDetailByPurchaseBillID:"StockDetail/GetStockDetailByPurchaseBillID",
GetStockSummary:"StockDetail/GetStockSummary",
// GetDetailReport:"StockDetail/GetDetailReport",

GetStockMaterials:"StockDetail/GetStockMatrials",
GetStockMatrialCategory:"StockDetail/GetStockMatrialCategory",
GetStockLocationType:"StockDetail/GetStockLocationType",
GeStocktLocation:"StockDetail/GeStocktLocation",
GetStock:"StockDetail/GetStock",
StockTransfers : "StockDetail/StockTransfers",
GetStockMatrialCategoryByLocation:"StockDetail/GetStockMatrialCategoryByLocation",
GetStockMaterialByLocation:"StockDetail/GetStockMaterialByLocation",
 

//Stock Transfer

GetStockTransferAll:"StockTransfer/GetStockTransferAll",
SaveStockTransfer:"StockTransfer/SaveStockTransfer",
DeleteStockTransferById:"StockTransfer/DeleteStockTransferById",

//General Manager

DeleteGeneralManagerById:"GeneralManager/DeleteGeneralManagerById",
GetGeneralManagerAll:"GeneralManager/GetGeneralManagerAll",
SaveGeneralManager:"GeneralManager/SaveGeneralManager",
ActiveGeneralManagerById:"GeneralManager/ActiveGeneralManagerById",

//GM Location
ActiveGmlocationById:"Gmlocation/ActiveGmlocationById",
SaveGmlocation:"Gmlocation/SaveGmlocation",
GetGmlocationAll:"Gmlocation/GetGmlocationAll",
DeleteGmlocationById:"Gmlocation/DeleteGmlocationById",
// Role

//Material Type

GetMaterialType:"MaterialType/GetMaterialType",
DeleteMaterialTypeById:"MaterialType/DeleteMaterialTypeById",
SaveMaterialType:"MaterialType/SaveMaterialType",


// work detail
DeleteWorkDetailById: "WorkDetail/DeleteWorkDetailById",
GetWorkDetialAll: "WorkDetail/GetWorkDetialAll",
GetWorkDetailById : "WorkDetail/GetWorkDetailById",
SaveWorkDetail : "WorkDetail/SaveWorkDetail",
GetWorkDetailByProjectId: "WorkDetail/GetWorkDetailByProjectId",

// work Assign
DeleteWorkAssignById: "WorkAssign/DeleteWorkAssignById",
GetWorkAssignAll :"WorkAssign/GetWorkAssignAll",
GetWorkAssignById : "WorkAssign/GetWorkAssignById",
SaveWorkAssign : "WorkAssign/SaveWorkAssign",
GetWorkAssignByProjectId : "WorkAssign/GetWorkAssignByProjectId",
GetLocatonByWorkAssign: "WorkAssign/GetLocatonByWorkAssign",

// Stock Assign 
DeleteStockAssginById:  "StockAssign/DeleteStockAssginById",
GetStockAssignAll : "StockAssign/GetStockAssignAll",
GetStockAssignById: "StockAssign/GetStockAssignById",
SaveStockAssign: "StockAssign/SaveStockAssign",
GenerateAssignedStockAsync: "StockAssign/GenerateAssignedStockAsync",


};
