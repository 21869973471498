import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { HttpService } from 'app/_services/http.service';
import { environment } from 'environments/environment.prod';
import { MenutriggerService } from 'app/_services/menutrigger.service';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;
  private dataChangeSubscription: Subscription;
  @Input()
  layout = 'vertical';

  @Input()
  menu: any;
  projectdata:any;
  // Private
  private _unsubscribeAll: Subject<any>;
;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(private http: HttpService, private _changeDetectorRef: ChangeDetectorRef, 
    private _coreMenuService: CoreMenuService,  private dataService: MenutriggerService,   private _authenticationService: AuthenticationService) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.dataChangeSubscription = this.dataService.dataChange$.subscribe(() => {

      //this.handleDataChange();
    });
  
  }

  

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */



  ngOnInit(): void {

    
 
   const currentUser = this._authenticationService.currentUserValue;

    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentUser = this._coreMenuService.currentUser;

 
      // Load menu
      this.menu = this._coreMenuService.getCurrentMenu();
   
      this._changeDetectorRef.markForCheck();
    });


  }



}
