import { Role } from './role';

export class User {
  id: number;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  avatar: string='user.jpg';
  role: Role;
  token?: string;
  loginId:number;
  roleId:number;
  locationId:number;
  locationTypeId:number;
}
