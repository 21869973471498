import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment.prod';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'app/_services/http.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;
user:any;
  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient,private http: HttpService, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    
    this.currentUser = this.currentUserSubject.asObservable();
 
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(userName: string, password: string) {



    // console.log('loginchek')
    // this.http.getAll(
    //   environment.ActiveProjectSectionById+"?userName="+userName + "&password=" + password).subscribe((result: any) => {
    //     if(result.isSuccess=="1"){
    //       if (result && result.token) {
    //         // store user details and jwt token in local storage to keep user logged in between page refreshes
    //         localStorage.setItem('currentUser', JSON.stringify(result));

    //         // Display welcome toast!
    //         setTimeout(() => {
    //           this._toastrService.success(
    //             'You have successfully logged in as an ' +
    //             result.role +
    //               ' user to eBuilder. Now you can start to explore. Enjoy! 🎉',
    //             '👋 Welcome, ' + result.firstName + '!',
    //             { toastClass: 'toast ngx-toastr', closeButton: true }
    //           );
    //         }, 2500);

    //         // notify
    //         this.currentUserSubject.next(result);
    //       }

    //       return result;
    //     }
    //     else{
    //     }

    //   });
      debugger;
    
    return this._http
      .post<any>(`${  environment.apiUrl + environment.Login + "?userName="+userName + "&password=" + password }`,{})
      .pipe(
        map(result => {
          // login successful if there's a jwt token in the response
          if (result.isSuccess == 1)
          {
          this.user=result.data;
  
          // console.log(user.loginDetail.id);
          if (result.isSuccess == 1 && this.user != null && this.user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(this.user));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                'You have successfully logged in as an ' +
                this.user.role +
                  ' user to eBuilder. Now you can start to explore. Enjoy! 🎉',
                '👋 Welcome, ' + this.user.firstName + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(this.user);
          }
        }
      
          return result
        })
      );
  }


  /**
   * User logout
   *
   */

    
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
}
